export const localStorageKeys = {
  accessToken: 'housebuild-accessToken',
  user: 'housebuild-user',
};

export const docsAnalyticsKeys = [
  'all',
  'go-green',
  'building-system',
  'regulation',
  'guideline',
  'regulations',
];

export const userAnalyticsKeys = [
  // 'Individual',
  'all',
  'individual-users',
  'professional-users',
  'advanced-users',
  'plus-users',
];
// export const userAnalyticsKeyMap = {
//   individual: 'Individual',
//   professional: 'Professional',
//   'subscribed-users': 'subscribed',
// } as Record<string, string>;

export const productsAnalyticsKeys = [
  'all',
  'buildingMaterialCount',
  'constructionCount',
  'showroomCount',
];
export const mortgageAnalyticsKeys = [
  'mortgage',
  'personal-loan',
  'green-loan',
];

export const insuranceAnalyticsKeys = [
  'home-insurance',
  'public-liability-insurance',
  'employers-liability-insurance',
  'professional-indemnity',
];

export const utilityAnalyticsKeys = [
  'gas-electricity',
  'broadband',
  'mobile-phone',
  'enquiry',
];

export const citiesAnalyticsKeys = ['all', 'uk', 'ie'];

export const couponSubscription: Array<{ label: string; value: string }> = [
  {
    label: 'Advanced',
    value: 'advanced',
  },
  {
    label: 'Professional',
    value: 'professional',
  },
];

// export const userAnalyticsKeys = Object.keys(userAnalyticsKeyMap);
