import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import coupons from '@/apiConfigs/coupons';
import { couponSubscription } from '@/constants';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Modal from '@/designComponents/Modal';
import { ModalContentWrapper } from '@/designComponents/Modal/style';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import { StyledDatePicker } from '@/pages/Advertisement/CreateAds';
import requestAPI from '@/utils/requestAPI';

export type CouponInitialValueType = {
  title?: string;
  code?: string;
  duration?: number;
  redeemLimit?: number;
  redeemExpiryDate?: Date;
  subscription?: string;
  redeemCount?: number;
  discount?: number;
};

const couponValidationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  code: yup.string().required('Code is required'),
  duration: yup
    .number()
    .required('Duration is required')
    .positive('Duration must be a positive number')
    .integer('Duration must be an integer'),
  redeemLimit: yup
    .number()
    .required('Redeem limit is required')
    .positive('Redeem limit must be a positive number')
    .integer('Redeem limit must be an integer'),
  redeemExpiryDate: yup
    .date()
    .required('Redeem expiry date is required')
    .nullable(),
  subscription: yup.string().required('Subscription is required'),
  redeemCount: yup
    .number()
    .required('Redeem count is required')
    .positive('Redeem count must be a positive number')
    .integer('Redeem count must be an integer'),
  discount: yup
    .number()
    .required('Percent discount is required')
    .positive('Percent discount must be a positive number')
    .max(100, 'Percent discount cannot be more than 100')
    .min(0, 'Percent discount cannot be less than 0'),
});

type Props = {
  isOpenModal: boolean;
  onClose: () => void;
  refetchData?: () => Promise<void>;
  couponId?: string;
};

const AddCoupon = ({ isOpenModal, onClose, refetchData, couponId }: Props) => {
  const formikRef = useRef<FormikProps<CouponInitialValueType>>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValues, setInitialValues] = useState<CouponInitialValueType>({
    redeemExpiryDate: new Date(),
    code: '',
    duration: 1,
    redeemCount: 1,
    redeemLimit: 1,
    subscription: '',
    title: '',
    discount: 1,
  });
  const getSingleCoupon = async (id: string) => {
    try {
      const data = await requestAPI(coupons.getSingleCoupon(id));
      if (data) {
        setInitialValues({ ...data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (couponId) {
      getSingleCoupon(couponId);
    }
  }, [couponId]);

  const monthArray = Array.from({ length: 12 }, (_, i) => i + 1).map(
    (item) => ({
      label: item.toString(),
      value: item.toString(),
    })
  );

  const handleAddCoupon = async (val: CouponInitialValueType) => {
    setLoading(true);
    const data: CouponInitialValueType = {
      code: val.code,
      duration: val.duration,
      redeemCount: val.redeemCount,
      redeemExpiryDate: val.redeemExpiryDate,
      redeemLimit: val.redeemLimit,
      subscription: val.subscription,
      title: val.title,
      discount: val.discount,
    };
    try {
      await requestAPI(coupons.addCoupon(data));
      toast.success('Coupon added successfully');
      await refetchData?.();
      onClose();
    } catch (e) {
      console.error(e);
      setErrorMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTag = async (val: CouponInitialValueType) => {
    setLoading(true);
    const data: CouponInitialValueType = {
      code: val.code,
      duration: val.duration,
      redeemCount: val.redeemCount,
      redeemExpiryDate: val.redeemExpiryDate,
      redeemLimit: val.redeemLimit,
      subscription: val.subscription,
      title: val.title,
      discount: val.discount,
    };
    try {
      await requestAPI(coupons.updateCoupon(couponId, data));
      toast.success('Coupon updated successfully');
      await refetchData();
      await getSingleCoupon(couponId);
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={onClose}
      title={couponId ? 'Update Coupon' : 'Add Coupon'}
      halfWidth
    >
      <ModalContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={couponId ? handleUpdateTag : handleAddCoupon}
          enableReinitialize
          innerRef={formikRef}
          validationSchema={couponValidationSchema}
        >
          {(formikProps) => {
            const { values, setFieldValue, errors, touched } = formikProps;
            return (
              <Form>
                <div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Title
                    </Typography>
                    <Input
                      title="title"
                      value={values.title}
                      onChange={(e) => setFieldValue('title', e.target.value)}
                    />
                    {errors.title && touched.title ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.title}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Discount(In %)
                    </Typography>
                    <Input
                      title="discount"
                      value={values.discount}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (value > 100) {
                          setFieldValue('discount', 100);
                        } else {
                          setFieldValue('discount', value);
                        }
                      }}
                      type="number"
                      max={100}
                    />
                    {errors.discount && touched.discount ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.discount}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Subscription
                    </Typography>
                    <Select
                      name="subscription"
                      selected={values.subscription}
                      onSelect={(value) => {
                        setFieldValue('subscription', value);
                      }}
                      options={couponSubscription}
                      placeholder="Select Subscription"
                    />
                    {errors.subscription && touched.subscription ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.subscription}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Coupon Code
                    </Typography>
                    <Input
                      title="code"
                      value={values.code.toUpperCase().trim()}
                      onChange={(e) =>
                        setFieldValue(
                          'code',
                          e.target.value.toUpperCase().trim()
                        )
                      }
                      maxLength={10}
                    />
                    {errors.code && touched.code ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.code}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Duration(In Months)
                    </Typography>

                    <Select
                      name="duration"
                      selected={values.duration.toString()}
                      onSelect={(value) => {
                        setFieldValue('duration', value);
                      }}
                      options={monthArray}
                      placeholder="Select Duration"
                    />
                    {errors.duration && touched.duration ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.duration}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Redeem Limit
                    </Typography>
                    <Input
                      title="redeemLimit"
                      value={values.redeemLimit}
                      type="number"
                      onChange={(e) =>
                        setFieldValue('redeemLimit', e.target.value)
                      }
                    />
                    {errors.redeemLimit && touched.redeemLimit ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.redeemLimit}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Redeem Expiry Date
                    </Typography>
                    <StyledDatePicker
                      selected={values.redeemExpiryDate}
                      onChange={(date) =>
                        setFieldValue('redeemExpiryDate', date)
                      }
                      showIcon
                    />
                    {errors.redeemExpiryDate && touched.redeemExpiryDate ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {String(errors.redeemExpiryDate)}
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      fontWeight="medium"
                      style={{ marginBottom: '10px' }}
                    >
                      Redeem Count
                    </Typography>
                    <Input
                      title="redeemCount"
                      value={values.redeemCount}
                      onChange={(e) =>
                        setFieldValue('redeemCount', e.target.value)
                      }
                    />
                    {errors.redeemCount && touched.redeemCount ? (
                      <div style={{ color: 'red', marginTop: '10px' }}>
                        {errors.redeemCount}
                      </div>
                    ) : null}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '20px',
                      gap: '20px',
                    }}
                  >
                    <Button
                      variant="gray"
                      radius="normal"
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" isLoading={loading} radius="normal">
                      {couponId ? 'Update' : 'Save'}
                    </Button>
                  </div>
                </div>
                {errorMessage && (
                  <div style={{ color: 'red', marginTop: '10px' }}>
                    {errorMessage}
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </ModalContentWrapper>
    </Modal>
  );
};

export default AddCoupon;
