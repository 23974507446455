import { CouponInitialValueType } from '@/pages/Coupons/CreateCoupon';
import { ConfigType } from '@/utils/requestAPI';

export type CouponsPaginationType = {
  limit?: number;
  total?: number;
  totalPages?: number;
  currentPage?: number;
  page?: number;
  search?: string;
};
const coupons = {
  getAllCoupons: (params?: CouponsPaginationType): ConfigType => {
    // const newParams = Object.keys(params).reduce((prev, curr) => {

    // },{})
    return {
      method: 'get',
      url: '/coupons',
      params,
    };
  },

  getSingleCoupon: (id: string): ConfigType => {
    return {
      method: 'get',
      url: `/coupons/${id}`,
    };
  },
  addCoupon: (data: CouponInitialValueType): ConfigType => {
    return {
      method: 'post',
      url: '/coupons',
      data,
    };
  },
  updateCoupon: (id: string, data: CouponInitialValueType): ConfigType => {
    return {
      method: 'put',
      url: `/coupons/${id}`,
      data,
    };
  },
};
export default coupons;
