// import { EditorState } from 'lexical';

import React, { useEffect, useState } from 'react';
import { GoChecklist } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { storiesApi } from '@/apiConfigs/stories';
import BellIcon from '@/assets/bellIcon.svg';
import Downarrow from '@/assets/downarrow.svg';
import Logo from '@/assets/icons/user.svg';
import uparrow from '@/assets/uparrow.svg';
import Button from '@/designComponents/Button';
import CardTab from '@/designComponents/CardTab';
import Select from '@/designComponents/Select';
import Typography from '@/designComponents/Typography';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { RootState, useAppDispatch } from '@/store';
import { getCount } from '@/store/dashboard/functions';
import requestAPI from '@/utils/requestAPI';

import Charts from './Charts';
import JobPosting from './JobPosting';
import JobCharts from './JobPosting/JobCharts';
import PromoCode from './PromoCode';
import { ContentWrapperStyled } from './style';
import SubscribedUsers, { TableProps } from './SubscribedUsers';

type Props = {};

const Dashboard = (_props: Props) => {
  const [stories, setStories] = useState([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dashboardCountData = useSelector((state: RootState) => state.dashboard);
  const { dashboard } = dashboardCountData;
  const [selectedUserCount, setSelectedUserCount] = useState(
    Number(dashboard?.newUsersCount?.Last7days) || 0
  );

  const [activeUserCountDropdown, setActiveUserCountDropdown] =
    useState('Last 7 Days');

  const totalSubscribedUser =
    dashboard?.subscribedUsers?.premiumYearly +
    dashboard?.subscribedUsers?.premiumMonthly +
    dashboard?.subscribedUsers?.advancedYearly +
    dashboard?.subscribedUsers?.advancedMonthly;

  const tableData: TableProps['tableData'] = {
    headings: [
      {
        key: 'premiumYearly',
        title: 'Premium Yearly Subscribed User',
      },
      {
        key: 'premiumMonthly',
        title: 'Premium Monthly Subscribed User',
      },
      {
        key: 'advancedYearly',
        title: 'Advanced Yearly Subscribed User',
      },
      {
        key: 'advancedMonthly',
        title: 'Advanced Monthly Subscribed User',
      },
      {
        key: 'total',
        title: 'Total',
      },
    ],
    body: {
      premiumYearly: dashboard?.subscribedUsers?.premiumYearly,
      premiumMonthly: dashboard?.subscribedUsers?.premiumMonthly,
      advancedYearly: dashboard?.subscribedUsers?.advancedYearly,
      advancedMonthly: dashboard?.subscribedUsers?.advancedMonthly,
      total: totalSubscribedUser,
    },
  };

  const userDropdownOption = [
    {
      label: 'Last 7 Days',
      value: dashboard?.newUsersCount?.Last7days,
    },
    {
      label: 'Today',
      value: dashboard?.newUsersCount?.Today,
    },
    {
      label: 'Last Week',
      value: dashboard?.newUsersCount?.lastWeek,
    },
    {
      label: 'Last Month',
      value: dashboard?.newUsersCount?.lastMonth,
    },
  ];

  const cardData: Array<{
    title: string;
    count: number;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  }> = [
    {
      title: 'New Users',
      count: dashboard?.newUsersCount?.Last7days || 0,
    },
    {
      title: 'Total Subscription',
      count: totalSubscribedUser || 0,
    },
    {
      title: 'Total Documents',
      count: dashboard?.documents || 0,
    },
    {
      title: 'Total Products',
      count: dashboard?.products || 0,
    },
    {
      title: 'Total Professionals',
      count: dashboard?.professionalUsers || 0,
    },
    {
      title: 'Total Stories',
      count: dashboard?.stories || 0,
    },
    {
      title: 'Journey Flow users',
      count: dashboard?.journeyFlowUsers || 0,
    },
    {
      title: 'Total Compliance Checklist Users',
      count: dashboard?.complianceChecklists?.uniqueOwners || 0,
    },
    {
      title: 'Total Compliance Checklists',
      count: dashboard?.complianceChecklists?.totalChecklists || 0,
      icon: GoChecklist,
    },
  ];

  useEffect(() => {
    const selectedData = userDropdownOption.find(
      (item) => item.label === activeUserCountDropdown
    );

    if (selectedData) {
      setSelectedUserCount(selectedData.value || 0);
    }
  }, [dashboard]);

  useEffect(() => {
    dispatch(getCount());
  }, [dispatch]);

  const handleUserCountSelect = (_, option) => {
    setSelectedUserCount(option.value);
    setActiveUserCountDropdown(option.label);
  };

  useHeaderTitle('Dashboard');

  const handleGetStories = async () => {
    try {
      const data = await requestAPI(storiesApi.getAllStories());
      setStories(data.data.data);
    } catch (error) {
      console.error('Error encountered: ', error);
    }
  };
  useEffect(() => {
    handleGetStories();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '10px',
            marginBottom: '20px',
          }}
        >
          {' '}
          {cardData.map((data, idx) => (
            <React.Fragment key={idx}>
              <CardTab
                enableBorder
                img={data.title !== 'Total Compliance Checklists' && Logo}
                title={data.title}
                icon={data.icon}
                count={idx === 0 ? selectedUserCount : data.count}
                key={idx}
                onClick={() => {}}
                dropDownComponent={
                  idx === 0 && (
                    <div
                      style={{
                        width: '150px',
                      }}
                    >
                      <Select
                        options={userDropdownOption}
                        name="stories"
                        placeholder={activeUserCountDropdown}
                        isHideBorder
                        isReduceIconGap
                        onSelect={handleUserCountSelect}
                        selected={selectedUserCount}
                      />
                    </div>
                  )
                }
              />
            </React.Fragment>
          ))}
        </div>
        <ContentWrapperStyled>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginBottom: '20px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography
                size="highlight"
                fontWeight="medium"
                style={{ marginRight: '15px' }}
              >
                Graph Statistics
              </Typography>
              <div
                className="stat"
                style={{ background: '#B5F5EC', border: '1px solid #b5f5ec' }}
              >
                <img src={uparrow} alt="up arrow" />
                <Typography style={{ color: '#08979c', fontSize: '16px' }}>
                  + 40 %
                </Typography>
              </div>
              <div
                className="stat"
                style={{ background: '#EFDBFF', border: '1px solid #EFDBFF' }}
              >
                <img src={Downarrow} alt="down arrow" />
                <Typography style={{ color: '#9254DE', fontSize: '16px' }}>
                  - 10 %
                </Typography>
              </div>
            </div>
            <Charts />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                gap: '10px',
                marginTop: '90px',
              }}
            >
              <Typography
                size="highlight"
                fontWeight="medium"
                style={{ marginRight: '15px' }}
              >
                Jobs Statistics
              </Typography>
              <div
                className="stat"
                style={{ background: '#B5F5EC', border: '1px solid #b5f5ec' }}
              >
                <img src={uparrow} alt="up arrow" />
                <Typography style={{ color: '#08979c', fontSize: '16px' }}>
                  + 30 %
                </Typography>
              </div>
              <div
                className="stat"
                style={{ background: '#372f3d', border: '1px solid #EFDBFF' }}
              >
                <img src={Downarrow} alt="down arrow" />
                <Typography style={{ color: '#9254DE', fontSize: '16px' }}>
                  - 20 %
                </Typography>
              </div>
            </div>
            <JobCharts />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              flex: 1,
            }}
          >
            <div
              style={{
                border: '1px solid #E8E8E8',
                borderRadius: '7px',
                padding: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <div style={{ display: 'flex', gap: '7px' }}>
                  <img src={BellIcon} alt="bell icon" />
                  <Typography size="title" fontWeight="medium">
                    Stories Approval Notifications
                  </Typography>
                </div>
                {/* <Select
                  options={dropDownOption}
                  name="stories"
                  placeholder="Select"
                  isHideBorder
                  isReduceIconGap
                /> */}
              </div>

              {stories?.slice(0, 2)?.map((story) => {
                const firstPhoto = story?.photos?.[0];

                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '30px',
                    }}
                    key={story?._id}
                  >
                    <div style={{ display: 'flex', gap: '30px' }}>
                      <div>
                        {firstPhoto && (
                          <img
                            src={firstPhoto.url}
                            alt="home"
                            key={firstPhoto?._id}
                            height={120}
                            width={120}
                            // style={{ objectFit: 'contain' }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '19px',
                        }}
                      >
                        <Typography size="highlight" fontWeight="medium">
                          {story?.title}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '7px',
                          }}
                        >
                          <img src={Logo} />
                          <Typography>Cameron Williamson</Typography>
                        </div>
                        <Typography>{story?.description}</Typography>
                        <Button
                          variant="gray"
                          radius="normal"
                          style={{
                            maxWidth: '80px',
                            border: '1px solid blue',
                            color: 'blue',
                            backgroundColor: 'white',
                          }}
                          onClick={() => navigate(`/stories/${story._id}`)}
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}

              <Typography
                style={{
                  textAlign: 'center',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  fontWeight: '500',
                  marginTop: '15px',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/stories')}
              >
                See all stories approval requests
              </Typography>
            </div>
            <JobPosting />
          </div>
        </ContentWrapperStyled>
        <ContentWrapperStyled>
          <PromoCode />
        </ContentWrapperStyled>
        {/* <TextEditor setEditorState={setEditorState} />
        <TextEditor readOnly initialHtml={editorState?.html} /> */}
        <div
          style={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography size="highlight" fontWeight="medium">
            Subscribed Users
          </Typography>
          <div>
            <SubscribedUsers tableData={tableData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
