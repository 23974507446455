import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import coupons, { CouponsPaginationType } from '@/apiConfigs/coupons';
import { ReactComponent as SearchSVG } from '@/assets/icons/search.svg';
import Pagination from '@/components/Pagination';
import Button from '@/designComponents/Button';
import Input from '@/designComponents/Input';
import Table, { TableDataType } from '@/designComponents/Table';
import useHeaderTitle from '@/hooks/useHeaderTitle';
import { debounce } from '@/utils/debounce';
import requestAPI from '@/utils/requestAPI';

import { SearchInputWrapperStyled } from '../Documents/style';
import AddCoupon, { CouponInitialValueType } from './CreateCoupon';

const CouponsMainPage = () => {
  const navigate = useNavigate();
  const [couponsData, setCouponsData] = useState<
    Array<CouponInitialValueType & { _id: string }>
  >([]);
  const [openCerateCouponModal, setOpenCreateCouponModal] = useState(false);
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    limit: 10,
    search: '',
  });
  const [pagination, setPagination] = useState({ totalPages: 1 });

  const getAllCoupons = async (params?: CouponsPaginationType) => {
    try {
      const data = await requestAPI(coupons.getAllCoupons(params));
      setCouponsData(data.data);
      setPagination({
        totalPages: data.pagination.totalPages,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useHeaderTitle('Coupons');

  const delayedFetch = useCallback(debounce(getAllCoupons, 300), []);

  useEffect(() => {
    getAllCoupons(paginationParams);
  }, [paginationParams.page, paginationParams.limit]);

  const tableData: TableDataType = {
    headings: [
      { key: 'title', label: 'Title' },
      { key: 'subscription', label: 'Subscription' },
      { key: 'couponCode', label: 'Coupon Code' },
      { key: 'discount', label: 'Discount(In %)' },
      { key: 'duration', label: 'Duration(In Months)' },
      { key: 'actions', label: 'Actions' },
    ],
    body: Array.isArray(couponsData)
      ? couponsData.map((coupon) => ({
          id: coupon._id,
          title: coupon.title,
          subscription: coupon.subscription,
          couponCode: coupon.code,
          discount: coupon.discount,
          duration: coupon.duration,
          actions: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="gray"
                radius="normal"
                onClick={() => navigate(coupon?._id)}
                style={{ marginLeft: '10px' }}
              >
                View
              </Button>
            </div>
          ),
        }))
      : [],
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPaginationParams((prevParams) => {
      const updatedParams = { ...prevParams, search: value, page: 1 };
      delayedFetch(updatedParams);
      return updatedParams;
    });
  };

  const handleCurrentPage = (page: number) => {
    setPaginationParams((prevParams) => ({ ...prevParams, page }));
  };

  const handleLimitChange = (limit: number) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      limit,
      page: 1,
    }));
  };

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchInputWrapperStyled>
            <Input
              value={paginationParams.search}
              onChange={handleInputChange}
              placeholder="Search"
              iconLeft={<SearchSVG />}
            />
          </SearchInputWrapperStyled>
          <Button radius="md" onClick={() => setOpenCreateCouponModal(true)}>
            New Coupon
          </Button>
          {openCerateCouponModal && (
            <AddCoupon
              isOpenModal={openCerateCouponModal}
              onClose={() => setOpenCreateCouponModal(false)}
              refetchData={getAllCoupons}
            />
          )}
        </div>
      </div>
      <Table
        tableData={tableData}
        noData="No data to display."
        showCheckBox={false}
        maxWidthForCardView="768px"
        headingBgColor="#f0f0f0"
        showMenuIcon={false}
        evenRowBgColor="#fafafa"
      />
      <Pagination
        activePage={paginationParams.page || 1}
        onCurrentPageChange={handleCurrentPage}
        totalPages={pagination?.totalPages || 1}
        limit={paginationParams.limit || 10}
        onLimitChange={handleLimitChange}
      />
    </div>
  );
};

export default CouponsMainPage;
