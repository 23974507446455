import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import coupons from '@/apiConfigs/coupons';
import Button from '@/designComponents/Button';
import Typography from '@/designComponents/Typography';
import theme from '@/theme';
import { toCapitalCase } from '@/utils/conversion';
import { humanReadableDate } from '@/utils/date';
import requestAPI from '@/utils/requestAPI';

import AddCoupon, { CouponInitialValueType } from '../CreateCoupon';
import { AdvertiseDetailsWrapper } from './style';

const CouponsDetailPage = () => {
  const { couponId } = useParams();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [couponsData, setCouponsData] = useState<
    CouponInitialValueType & { _id: string }
  >({
    _id: '',
    code: '',
    duration: 1,
    redeemCount: 1,
    redeemExpiryDate: new Date(),
    redeemLimit: 1,
    subscription: 'Advanced',
    title: '',
  });

  const getSingleCoupon = async (id: string) => {
    try {
      const data = await requestAPI(coupons.getSingleCoupon(id));
      setCouponsData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const formattedCouponsData = Object.entries(couponsData)
    .filter(([key]) => key !== '__v')
    .map(([key, value]) => ({
      label:
        key === '_id'
          ? 'Id'
          : key === 'code'
            ? 'Coupon Code'
            : toCapitalCase(key),
      value,
    }));

  useEffect(() => {
    if (couponId) {
      getSingleCoupon(couponId);
    }
  }, [couponId]);

  return (
    <AdvertiseDetailsWrapper>
      <div className="main-content">
        <div className="heading">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              fontFamily="jost"
              fontWeight="medium"
              size="heading"
              color="dark.neutral_60"
            >
              Coupon Details
            </Typography>
            <Button
              variant="primary"
              radius="normal"
              onClick={() => setIsEditModalOpen(true)}
            >
              Edit Coupon
            </Button>
            <AddCoupon
              isOpenModal={isEditModalOpen}
              onClose={() => setIsEditModalOpen(false)}
              couponId={couponId}
              refetchData={() => getSingleCoupon(couponId)}
            />
          </div>

          <hr
            style={{
              backgroundColor: theme.colors.dark.neutral_50,
              border: 'none',
              height: '1px',
            }}
          />
        </div>
        <div className="main-text">
          {formattedCouponsData.map((item, idx) => (
            <div className="text" key={idx}>
              <Typography className="text-value">{item.label}</Typography>
              <Typography className="text-value">
                {item.label === 'Redeem Expiry Date'
                  ? humanReadableDate(item.value.toString())
                  : item.value.toString()}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </AdvertiseDetailsWrapper>
  );
};

export default CouponsDetailPage;
