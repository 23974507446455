// import { useTheme } from 'styled-components';

import Typography from '@/designComponents/Typography';
import { toCapitalCase } from '@/utils/conversion';

import { SubscriptionWrapper } from '../../pages/Users/Details/style';
import Tag from './Tag';

type Props = {
  isFree?: boolean;
  price?: string;
  subscriptionName?: string;
  status?: string;
};
const Subscription = ({ isFree = false, subscriptionName, status }: Props) => {
  // const theme = useTheme();
  return (
    <SubscriptionWrapper className="subscription">
      <div className="premium-tag">
        <Typography>{toCapitalCase(subscriptionName)}</Typography>
        {status && (
          <Tag
            title={status === 'active' ? 'Active' : 'In Active'}
            variant={status === 'active' ? 'active' : 'danger'}
          />
        )}
      </div>
      <Typography
        color="primary"
        fontWeight="medium"
        fontFamily="roboto"
        size="highlight"
      >
        {isFree ? (
          'Free'
        ) : (
          <>
            {/* € {price}{' '}
            <span style={{ color: theme.colors.dark.neutral_20 }}>•</span>{' '}
            <span
              style={{
                fontWeight: theme.fontWeights.normal,
                fontSize: theme.fontSize.normal,
                lineHeight: '16.41px',
                color: theme.colors.dark.neutral_450,
              }}
            >
              Per Month
            </span> */}
          </>
        )}
      </Typography>
    </SubscriptionWrapper>
  );
};

export default Subscription;
